import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

const products = [
  { id: 1, name: 'Minoxidil 5%', price: 1890, imgSrc: '/assets/Minoxidil5.png' },
  { id: 2, name: 'Minoxidil 5% - Low Alcohol', price: 1990, imgSrc: '/assets/MinoxidilLowAlcohol.png' },
  { id: 3, name: 'Minoxidil 2% - Low Alcohol - Women', price: 1790, imgSrc: '/assets/Minoxidil2Women.png' },
  { id: 4, name: 'Minoxidil Foam', price: 2490, imgSrc: '/assets/MinoxidilFoam.png' },
  { id: 5, name: 'Minoxidil Foam - Women', price: 2490, imgSrc: '/assets/MinoxidilWomenFoam.png' },
  { id: 6, name: 'DermaRoller', price: 2690, imgSrc: '/assets/Dermaroller.png' },
 
  { id: 7, name: 'Big Shampoo - Men', price: 3990, imgSrc: '/assets/MinoxidilShampooMenBig.png' },
  { id: 8, name: 'Small Shampoo - Men', price: 2990, imgSrc: '/assets/MinoxidilShampooMenSmall.png' },
  { id: 9, name: 'Big Shampoo - Women', price: 3990, imgSrc: '/assets/MinoxidilShampooWomenBig.png' },
  { id: 10, name: 'Small Shampoo - Women', price: 2990, imgSrc: '/assets/MinoxidilShampooWomenSmall.png' },

 
  { id: 11, name: 'Normal Combo', price: 4990, imgSrc: '/assets/NormalCombo.png' },
  { id: 12, name: 'Low Alcohol Combo', price: 5290, imgSrc: '/assets/LowAlcoholCombo.png' },
  { id: 13, name: 'Low Alcohol Combo - Women', price: 4690, imgSrc: '/assets/FemaleCombo.png' },
  { id: 14, name: 'Foam Combo', price: 5990, imgSrc: '/assets/FoamCombo.png' },
  { id: 15, name: 'Women Foam Combo', price: 5990, imgSrc: '/assets/FoamCombo.png' },

];

const OrderForm = () => {
  const { t } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    country: '',
    email: '',
    phoneNumber: '',
    street: '',
    postcode: '',
    city: '',
  });

  const toggleProduct = (id) => {
    setSelectedProducts(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(productId => productId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedProductNames = products
      .filter(product => selectedProducts.includes(product.id))
      .map(product => product.name)
      .join(', ');

    const totalPrice = products
      .filter(product => selectedProducts.includes(product.id))
      .reduce((total, product) => total + product.price, 0);

    const emailData = {
      ...formData,
      selectedProducts: selectedProductNames,
      totalPrice: `${totalPrice} ALL`
    };

    sendFormData(emailData);
  };

  const sendFormData = (data) => {
    const templateParams = {
      name: data.name,
      surname: data.surname,
      country: data.country,
      email: data.email,
      phoneNumber: data.phoneNumber,
      street: data.street,
      postcode: data.postcode,
      city: data.city,
      selectedProducts: data.selectedProducts,
      totalPrice: data.totalPrice,
    };

    console.log('Sending email with template params:', templateParams);

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Form submitted!');
      }, (err) => {
        console.error('FAILED...', err);
        alert('Failed to send the form. Please try again later.');
      });
  };

  const totalPrice = products
    .filter(product => selectedProducts.includes(product.id))
    .reduce((total, product) => total + product.price, 0);

  return (
    <section className="container order-section">
      <h2 className="order-title">{t('orderPage.form.title')}</h2>
      <p>{t('orderPage.form.description')}</p>
      <form className="order-form" onSubmit={handleSubmit}>
        <h3>{t('orderPage.form.informationTitle')}</h3>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder={t('orderPage.form.namePlaceholder')}
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="surname"
            placeholder={t('orderPage.form.surnamePlaceholder')}
            value={formData.surname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="country"
            placeholder={t('orderPage.form.countryPlaceholder')}
            value={formData.country}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder={t('orderPage.form.emailPlaceholder')}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="phoneNumber"
            placeholder={t('orderPage.form.phoneNumberPlaceholder')}
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <h3>{t('orderPage.form.addressTitle')}</h3>
        <p className='transport-p'>{t('orderPage.form.transportNote')}</p>
        <div className="form-group">
          <input
            type="text"
            name="street"
            placeholder={t('orderPage.form.streetPlaceholder')}
            value={formData.street}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="postcode"
            placeholder={t('orderPage.form.postcodePlaceholder')}
            value={formData.postcode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="city"
            placeholder={t('orderPage.form.cityPlaceholder')}
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <h3>{t('orderPage.form.productSelectionTitle')}</h3>
        <div className="product-selection">
          {products.map(product => (
            <div
              key={product.id}
              className={`product ${selectedProducts.includes(product.id) ? 'selected' : ''}`}
              onClick={() => toggleProduct(product.id)}
            >
              <img src={product.imgSrc} alt={product.name} />
              <span>{product.name}</span>
            </div>
          ))}
        </div>
        <div className="total-price">
         <h4> Total: { totalPrice } ALL</h4>
        </div>
        <button type="submit" className="btn btn-secondary btn-submit-form">{t('orderPage.form.submitButton')}</button>
      </form>
    </section>
  );
};

export default OrderForm;
