import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faChessKing, faLeaf, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import SplineViewer from './components/SplineViewer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';




const types = [
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil5.png`,
    title: 'MinoCare 5%',
    description: 'Minocare 5% minoxidil solution is a powerful treatment designed to stimulate hair follicles and promote hair regrowth. Clinically proven to increase hair density, this product is ideal for men experiencing thinning hair or hair loss. The solution is easy to apply and integrates seamlessly into your daily routine.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilLowAlcohol.png`,
    title: 'MinoCare 5% - Low Alcohol',
    description: 'For those with sensitive scalps, Minocare offers a low alcohol version of the 5% minoxidil solution. This formulation reduces the risk of irritation while still delivering the same effective hair regrowth benefits. It’s a gentler option without compromising on results, ensuring comfort and effectiveness.',
    link: 'order',
  },

  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil2Women.png`,
    title: 'MinoCare 2% - Low Alcohol - Women',
    description: 'Specifically formulated for women, Minoxidil 2% offers a lower concentration suitable for mild to moderate hair thinning. It stimulates hair follicles to promote regrowth and is gentle on the scalp, making it ideal for daily use in women experiencing early signs of hair loss.',
    link: 'order',
  },

  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/Dermaroller.png`,
    title: 'MinoCare DermaRoller',
    description: 'The Minocare dermaroller is an excellent addition to your hair care routine. This tool features tiny needles that create micro-channels in the scalp, enhancing the absorption and effectiveness of minoxidil treatments. Regular use of the dermaroller can improve blood circulation and stimulate hair follicles, promoting healthier and stronger hair growth.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilFoam.png`,
    title: 'MinoCare Foam',
    description: 'The minoxidil foam is a convenient and mess-free alternative to the liquid solution. It is easy to apply, quickly absorbed, and dries without leaving any residue. The foam formulation provides the same benefits as the 5% minoxidil solution, helping to stimulate hair growth and increase hair density.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilWomenFoam.png`,
    title: 'MinoCare Foam - Women',
    description: 'Designed specifically for women, the Minoxidil Women Foam offers an easy-to-apply solution to combat hair thinning and promote regrowth. The foam formulation is quick-drying and non-greasy, making it a convenient addition to your daily hair care routine.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenSmall.png`,
    title: 'MinoCare Shampoo Small for Men',
    description: 'The Minoxidil Shampoo Small for Men combines the benefits of minoxidil with a gentle cleansing shampoo. Ideal for daily use, this shampoo helps to stimulate hair follicles while keeping your hair clean and healthy. The small size is perfect for those trying out the product or for travel convenience.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenBig.png`,
    title: 'MinoCare Shampoo Big for Men',
    description: 'For regular users, the Minoxidil Shampoo Big for Men offers a larger quantity, ensuring you have a consistent supply. This shampoo not only cleanses your hair but also helps promote hair growth and thickness with the power of minoxidil.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenSmall.png`,
    title: 'MinoCare Shampoo Small for Women',
    description: 'The Minoxidil Shampoo Small for Women is formulated to meet the unique needs of women experiencing hair thinning. This gentle shampoo cleanses the scalp while delivering minoxidil to stimulate hair growth. The small size is perfect for first-time users or travel.',
    link: 'order',
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenBig.png`,
    title: 'MinoCare Shampoo Big for Women',
    description: 'Offering a larger size for regular use, the Minoxidil Shampoo Big for Women ensures that you have enough product to maintain your hair care regimen. This shampoo combines effective cleansing with minoxidil treatment to promote thicker, healthier hair.',
    link: 'order',
  },

];

const reviewData = [
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil5.png`,
    title: 'MinoCare 5%',
    reviews: {
      fiveStars: 23,
      fourStars: 17,
      threeStars: 8,
      twoStars: 3,
      oneStar: 1,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilLowAlcohol.png`,
    title: 'MinoCare 5% - Low Alcohol',
    reviews: {
      fiveStars: 15,
      fourStars: 10,
      threeStars: 5,
      twoStars: 2,
      oneStar: 1,
    },
  },

  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/Minoxidil2Women.png`,
    title: 'MinoCare 2% - Low Alcohol - Women',
    reviews: {
      fiveStars: 16,
      fourStars: 9,
      threeStars: 4,
      twoStars: 1,
      oneStar: 0,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/Dermaroller.png`,
    title: 'DermaRoller',
    reviews: {
      fiveStars: 30,
      fourStars: 20,
      threeStars: 10,
      twoStars: 5,
      oneStar: 2,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilFoam.png`,
    title: 'MinoCare Foam',
    reviews: {
      fiveStars: 12,
      fourStars: 6,
      threeStars: 2,
      twoStars: 0,
      oneStar: 0,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilWomenFoam.png`,
    title: 'MinoCare Foam - Woman',
    reviews: {
      fiveStars: 18,
      fourStars: 12,
      threeStars: 4,
      twoStars: 1,
      oneStar: 0,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenSmall.png`,
    title: 'MinoCare Shampoo Small for Men',
    reviews: {
      fiveStars: 10,
      fourStars: 8,
      threeStars: 3,
      twoStars: 1,
      oneStar: 0,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooMenBig.png`,
    title: 'MinoCare Shampoo Big for Men',
    reviews: {
      fiveStars: 25,
      fourStars: 15,
      threeStars: 5,
      twoStars: 2,
      oneStar: 1,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenSmall.png`,
    title: 'MinoCare Shampoo Small for Women',
    reviews: {
      fiveStars: 14,
      fourStars: 9,
      threeStars: 3,
      twoStars: 1,
      oneStar: 0,
    },
  },
  {
    imgSrc: `${process.env.PUBLIC_URL}/assets/MinoxidilShampooWomenBig.png`,
    title: 'MinoCare Shampoo Big for Women',
    reviews: {
      fiveStars: 22,
      fourStars: 13,
      threeStars: 4,
      twoStars: 1,
      oneStar: 0,
    },
  },
 
];


const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Dynamically load the Spline Viewer script
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@splinetool/viewer@1.7.2/build/spline-viewer.js';
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <header className="header-home" id="intro">
        <div className="container header__container">
          <div className="header__left">
            <h1>{t('header.title')}</h1>
            <p>{t('header.description')}</p>
            <a href="/order" className="btn btn-secondary">{t('header.orderButton')}</a>
          </div>
          <div className="header__right" id="spline-container">
            <SplineViewer url="https://prod.spline.design/TRQZ0-9RnYAfXJnm/scene.splinecode" />
          </div>
        </div>
        <img
          srcSet={`${process.env.PUBLIC_URL}/assets/Hero Image-2000w.jpg 2000w,
                   ${process.env.PUBLIC_URL}/assets/Hero Image-1000w.jpg 1000w,
                   ${process.env.PUBLIC_URL}/assets/Hero Image-500w.jpg 500w`}
          sizes="(min-width: 1000px) 2000px, (min-width: 600px) 1000px, 500px"
          src={`${process.env.PUBLIC_URL}/assets/Hero Image-500w.jpg`}
          alt="Hero Image"
          style={{ display: 'none' }}
        />
      </header>


      <section className="about-section">
        <div className="about-content">
          <div className="about-left">
            <img src={`${process.env.PUBLIC_URL}/assets/MinoCare Logo.png`} alt="Logo" className="about-image" />
            <h1>{t('logoShowcase.title')}</h1>
          </div>
          <div className="about-right">
            <h2>{t('about.title')}</h2>
            <p>{t('about.description')}</p>
            <a href="https://www.instagram.com/minocare.al/" className="btn btn-secondary">{t('about.getToKnowButton')}</a>
          </div>
        </div>
      </section>

      <h2 className='title-mino'>{t('whyUs.title')}</h2>
      <section className="card-section container">
        <div className="card">
        <h1>{t('whyUs.effective.title')}</h1>
          <div className="card-content">
            <FontAwesomeIcon icon={faCog} className="feature-icon" />
            <p>{t('whyUs.effective.description')}</p>
          </div>
        </div>
        <div className="card">
          <h1>{t('whyUs.selfEsteem.title')}</h1>
          <div className="card-content">
            <FontAwesomeIcon icon={faChessKing} className="feature-icon" />
            <p>{t('whyUs.selfEsteem.description')}</p>
          </div>
        </div>
        <div className="card">
          <h1>{t('whyUs.natural.title')}</h1>
          <div className="card-content">
            <FontAwesomeIcon icon={faLeaf} className="feature-icon" />
            <p>{t('whyUs.natural.description')}</p>
          </div>
        </div>
      </section>

      {types.length > 0 && (
        <section className="container types-section">
          <h2 className="types-title">{t('products.title')}</h2>
          <Swiper
            spaceBetween={100}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            modules={[Pagination, Navigation, EffectCoverflow, Autoplay]}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              bulletClass: 'product-swiper-pagination-bullet',
              bulletActiveClass: 'product-swiper-pagination-bullet-active',
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 100,
              },
            }}
          >
            {types.map((type, index) => (
              <SwiperSlide key={index}>
                <div className="types-slide">
                  <img src={type.imgSrc} alt={type.title} />
                  <div>
                    <h4>{type.title}</h4>
                    <p>{type.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div>
          </Swiper>
          <a href={`order`} className="btn btn-secondary order-btn">{t('products.orderButton')}</a>
        </section>
      )}

      {reviewData.length > 0 && (
        <section className="container reviews-section">
          <h2 className="reviews-title">{t('reviews.title')}</h2>
          <Swiper
            spaceBetween={100}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            modules={[Pagination, Navigation, EffectCoverflow, Autoplay]}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              bulletClass: 'review-swiper-pagination-bullet',
              bulletActiveClass: 'review-swiper-pagination-bullet-active',
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 100,
              },
            }}
          >
            {reviewData.map((product, index) => (
              <SwiperSlide key={index}>
                <div className="review-slide">
                  <img src={product.imgSrc} alt={product.title} />
                  <div className="review-content">
                    <h4>{product.title}</h4>
                    <div className="stars">
                      <div className="star">
                        ★ ★ ★ ★ ★ <div className="slider" style={{ width: `${(product.reviews.fiveStars / 30) * 100}%` }}></div> {product.reviews.fiveStars}
                      </div>
                      <div className="star">
                        ★ ★ ★ ★ ☆ <div className="slider" style={{ width: `${(product.reviews.fourStars / 30) * 100}%` }}></div> {product.reviews.fourStars}
                      </div>
                      <div className="star">
                        ★ ★ ★ ☆ ☆ <div className="slider" style={{ width: `${(product.reviews.threeStars / 30) * 100}%` }}></div> {product.reviews.threeStars}
                      </div>
                      <div className="star">
                        ★ ★ ☆ ☆ ☆ <div className="slider" style={{ width: `${(product.reviews.twoStars / 30) * 100}%` }}></div> {product.reviews.twoStars}
                      </div>
                      <div className="star">
                        ★ ☆ ☆ ☆ ☆ <div className="slider" style={{ width: `${(product.reviews.oneStar / 30) * 100}%` }}></div> {product.reviews.oneStar}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div>
          </Swiper>
          <a href={`order`} className="btn btn-secondary order-btn">{t('products.orderButton')}</a>
        </section>
      )}

      <section id="logo-showcase" className="container logo-showcase">
        <img src={`${process.env.PUBLIC_URL}/assets/MinoCare Logo.png`} alt="Synaps Logo" className="logo-image" />
      </section>
      <h1 className='logo-showcase-brand'>{t('header.title')}</h1>
      <h1 className='logo-showcase-slogan'>{t('logoShowcase.title')}</h1>

      <div className="container footer-img">
        <div className="footer-bottom">
          <div className="footer-column footer-social">
            <h4>{t('footer.location')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <FontAwesomeIcon icon={faLocationDot} className="footer-img location-icon" />
                <a href="https://maps.app.goo.gl/Tpt5iiLCwdZZ1Dak9" className="footer-link">{t('footer.locationLink')}</a>
              </div>
            </div>
          </div>
          <div className="footer-column footer-socials">
            <h4>{t('footer.contact')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/email.png`} alt="Email" />
                <a href="mailto:contact@minocare.al" className="footer-link">{t('footer.email')}</a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/phone-call.png`} alt="Phone Number" />
                <a href="tel:+355696664258" className="footer-link">{t('footer.phone')}</a>
              </div>
            </div>
          </div>
          <div className="footer-column footer-socials">
            <h4>{t('footer.socials')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/facebook.png`} alt="Facebook" />
                <a href="https://www.facebook.com" className="footer-link">{t('footer.facebook')}</a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/instagram.png`} alt="Instagram" />
                <a href="https://www.instagram.com/minocare.al/" className="footer-link">{t('footer.instagram')}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          <p className="footer-copy">{t('footer.copyright')}</p>
          <p className="footer-copy-synaps">{t('footer.designedBy')} <a className="blog-log-in-btn footer-copy-designed-by-synaps" href="https://www.synapslimited.eu">{t('footer.designedByLink')}</a></p>
        </div>
      </div>
    </div>
  );
}

export default Home;
