import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Home from './home';
import Order from './order';

const App = () => {
  const location = useLocation();

  const getBackgroundClass = (pathname) => {
    switch (pathname) {
      case '/':
        return 'home-background';
      default:
        return 'order-background';
    }
  };

  return (
    <div className={`App ${getBackgroundClass(location.pathname)}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/order" element={<Order />} />
      </Routes>
    </div>
  );
};

export default App;
