import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Navbar from './components/Navbar';
import SplineViewer from './components/SplineViewer';
import OrderForm from './components/OrderForm';

const Order = () => {
  const { t } = useTranslation();

  useEffect(() => {
    // Dynamically load the Spline Viewer script
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://unpkg.com/@splinetool/viewer@1.7.2/build/spline-viewer.js';
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <header className="header-order" id="intro">
        <div className="container header__container">
          <div className="header__left">
            <h1>{t('orderPage.header.title')}</h1>
            <p>{t('orderPage.header.description')}</p>
            <a href="https://www.instagram.com/minocare.al/" className="btn btn-secondary">{t('orderPage.header.contactButton')}</a>
          </div>
          <div className="header__right" id="spline-container">
            <SplineViewer url="https://prod.spline.design/XAl213oqYk4Z4xRF/scene.splinecode" />
          </div>
        </div>
      </header>

      <OrderForm />

      <section id="logo-showcase" className="container logo-showcase">
        <img src={`${process.env.PUBLIC_URL}/assets/MinoCare Logo.png`} alt="Synaps Logo" className="logo-image" />
      </section>
      <h1 className='logo-showcase-brand'>{t('header.title')}</h1>
      <h1 className='logo-showcase-slogan'>{t('logoShowcase.title')}</h1>

      <div className="container footer-img">
        <div className="footer-bottom">
          <div className="footer-column footer-social">
            <h4>{t('footer.location')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <FontAwesomeIcon icon={faLocationDot} className="footer-img location-icon" />
                <a href="https://maps.app.goo.gl/Tpt5iiLCwdZZ1Dak9" className="footer-link">{t('footer.locationLink')}</a>
              </div>
            </div>
          </div>
          <div className="footer-column footer-socials">
            <h4>{t('footer.contact')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/email.png`} alt="Email" />
                <a href="mailto:contact@minocare.al" className="footer-link">{t('footer.email')}</a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/phone-call.png`} alt="Phone Number" />
                <a href="tel:+355696664258" className="footer-link">{t('footer.phone')}</a>
              </div>
            </div>
          </div>
          <div className="footer-column footer-socials">
            <h4>{t('footer.socials')}</h4>
            <div className="socials-container">
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/facebook.png`} alt="Facebook" />
                <a href="https://www.facebook.com" className="footer-link">{t('footer.facebook')}</a>
              </div>
              <div className="social-row">
                <img src={`${process.env.PUBLIC_URL}/assets/instagram.png`} alt="Instagram" />
                <a href="https://www.instagram.com/minocare.al/" className="footer-link">{t('footer.instagram')}</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          <p className="footer-copy">{t('footer.copyright')}</p>
          <p className="footer-copy-synaps">{t('footer.designedBy')} <a className="blog-log-in-btn footer-copy-designed-by-synaps" href="https://www.synapslimited.eu">{t('footer.designedByLink')}</a></p>
        </div>
      </div>
    </div>
  );
};

export default Order;
